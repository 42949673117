import * as React from 'react'
import { Link } from 'gatsby'
//import { StaticImage } from "gatsby-plugin-image"

import Layout from '/src/components/layout'
import { first, functionsTable, functionsButtons } from '/src/styles/funktioner.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faMessage, faMobileScreenButton, faPhotoFilm, faRecycle, faShapes, faSignature, faUserGear, faCircleMinus, faElevator, faGaugeHigh, faGear, faGlobe, faNewspaper, faCircleCheck, faCalendarDays, faFile, faBell, faListOl, faCircleExclamation, faCalendar, faCheckToSlot, faBook, faFileContract, faPeopleCarryBox, faFileInvoiceDollar, faPaintRoller, faQuestion, faChartPie, faCirclePlus, faCircle, faEnvelopesBulk, faCertificate, faPercent, faPersonShelter, faCode, faBullhorn, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import smhiIconRed from '/src/images/smhi-icons/red.svg'
import smhiIconOrange from '/src/images/smhi-icons/orange.svg'
import smhiIconYellow from '/src/images/smhi-icons/yellow.svg'

const FunktionerPage = () => {
	return (
		<Layout pageTitle="Funktioner" showBox={false}>

			<div className="pageRows">

				<div className={first}>
					<div style={{display: 'inline-block', paddingBottom: '0px'}}>
						<div>
							<h2 className="center" style={{fontSize: '58px'}}>Tre delar</h2>
							<p style={{fontSize: '22px', marginTop: '25px'}}>Brfify består av tre huvuddelar som anpassas utifrån era färger, inställningar och innehåll.<br/>Varje fastighetsägare väljer själv vilka funktioner som ska vara synliga för de boende.</p>
						</div>
					</div>

					<div>

						<div>
							<FontAwesomeIcon icon={faGlobe} style={{fontSize: '74px', color: '#BACCFF'}} />
							<p style={{color: '#783AD0', fontSize: '28px', fontWeight: 'bold', marginTop: '25px'}}>Webbsida</p>
							<p style={{marginTop: '15px'}}>Snygg, snabb &amp; modern webbsida som gör det lätt för boende att hitta publik information dygnet runt och slippa ställa frågor. Potentiella köpare/hyresgäster får ett gott intryck och webbsidan fungerar lika bra på datorn som i mobilen.</p>
						</div>

						<div>
							<FontAwesomeIcon icon={faMobileScreenButton} style={{fontSize: '74px', color: '#BACCFF'}} />
							<p style={{color: '#783AD0', fontSize: '28px', fontWeight: 'bold', marginTop: '25px'}}>App</p>
							<p style={{marginTop: '15px'}}>Lättanvända Brfify-appen underlättar boendes vardag genom exempelvis att de kan läsa nyheter &amp; information, boka tvättstuga, rösta, ställa sig i kö, hitta portkod, hantera digitala nycklar, skapa &amp; visa ärenden och mycket mer. Gratis nedladdning och helt utan lösenord.</p>
						</div>

						<div>
							<FontAwesomeIcon icon={faUserGear} style={{fontSize: '74px', color: '#BACCFF'}} />
							<p style={{color: '#783AD0', fontSize: '28px', fontWeight: 'bold', marginTop: '25px'}}>Admin</p>
							<p style={{marginTop: '15px'}}>I det användarvänliga admin-verktyget hanteras webbsidans &amp; appens innehåll &amp; funktioner. Exempelvis skrivs nyheter endast en gång och visas både i appen och på webbsidan. I admin hanteras enkelt även exempelvis registret med boende.</p>
						</div>

					</div>
				</div>

				<div>
					<div style={{gridTemplateColumns: 'auto'}}>
						<div>
							<h2 className="center">Massor av funktioner</h2>
							<p className="intro" style={{textAlign: 'center'}}>Brfify har en mängd funktioner som underlättar vardagen för olika roller inblandade i en fastighet. Dessutom blir funktionerna ständigt fler och bättre. Varmt välkommen med förslag till <a href="mailto:hej@brfify.se">hej@brfify.se</a></p>

							<div className={functionsButtons + " buttons"}>
								<a href="#nyheter">
									<FontAwesomeIcon icon={faNewspaper} />
									<p>Nyheter</p>
								</a>
								<a href="#sidor">
									<FontAwesomeIcon icon={faFile} />
									<p>Sidor</p>
								</a>
								<a href="#viktigadatum">
									<FontAwesomeIcon icon={faCircleExclamation} mask={faCalendar} transform="shrink-10 down-2.7" />
									<p>Viktiga datum</p>
								</a>
								<a href="#samhallsinformation">
									<FontAwesomeIcon icon={faBullhorn} />
									<p style={{fontSize: '17px'}}>Samhälls-information</p>
								</a>
								<a href="#bokning">
									<FontAwesomeIcon icon={faCalendarDays} />
									<p>Bokning</p>
								</a>
								<a href="#notiser">
									<FontAwesomeIcon icon={faBell} />
									<p>Notiser</p>
								</a>
								<a href="#kointresse">
									<FontAwesomeIcon icon={faListOl} />
									<p>Kö &amp; intresse</p>
								</a>
								<a href="#atkomst">
									<FontAwesomeIcon icon={faKey} />
									<p>Åtkomst</p>
								</a>
								<a href="#arenden">
										<FontAwesomeIcon icon={faMessage} />
									<p>Ärenden</p>
								</a>
								<a href="#rostning">
									<FontAwesomeIcon icon={faCheckToSlot} />
									<p>Röstning &amp; tycka till</p>
								</a>
								<a href="#erbjudanden">
									<FontAwesomeIcon icon={faPercent} mask={faCertificate} transform="shrink-8 up-0.5" />
									<p>Erbjudanden</p>
								</a>
								<a href="#atervinning">
									<FontAwesomeIcon icon={faRecycle} />
									<p>Återvinning</p>
								</a>
								<a href="#hissar">
									<FontAwesomeIcon icon={faElevator} />
									<p>Hissar</p>
								</a>
								<a href="#forbrukningsstatistik">
									<FontAwesomeIcon icon={faGaugeHigh} />
									<p style={{fontSize: '16px'}}>Förbruknings-statistik</p>
								</a>
								{ /*
								<a href="#facebookgrupp">
									<FontAwesomeIcon icon={faFacebook} />
									<p>Facebook-grupp</p>
								</a>
								*/}
								<a href="#fastighetsdagbok">
									<FontAwesomeIcon icon={faBook} />
									<p>Fastighets-dagbok</p>
								</a>
								<a href="#avtalleverantorer">
									<FontAwesomeIcon icon={faFileContract} />
									<p>Avtal &amp; leverantörer</p>
								</a>
								<a href="#signering">
									<FontAwesomeIcon icon={faSignature} />
									<p>Signering</p>
								</a>
								<a href="#objekt">
									<FontAwesomeIcon icon={faShapes} />
									<p>Objekt</p>
								</a>
								<a href="#inuttrade">
									<FontAwesomeIcon icon={faPeopleCarryBox} />
									<p>In- &amp;<br/>utträde</p>
								</a>
								<a href="#avier">
									<FontAwesomeIcon icon={faFileInvoiceDollar} />
									<p>Avier</p>
								</a>
								<a href="#inarheten">
									<FontAwesomeIcon icon={faMapLocationDot} />
									<p>I närheten</p>
								</a>
								<a href="#bibliotek">
									<FontAwesomeIcon icon={faPhotoFilm} />
									<p>Bibliotek</p>
								</a>
								<a href="#design">
									<FontAwesomeIcon icon={faPaintRoller} />
									<p>Design</p>
								</a>
								<a href="#statistik">
									<FontAwesomeIcon icon={faChartPie} />
									<p>Statistik</p>
								</a>
								<a href="#admin">
									<FontAwesomeIcon icon={faGear} />
									<p>Admin</p>
								</a>
								<a href="#api">
									<FontAwesomeIcon icon={faCode} />
									<p>API</p>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div>
					<a id="nyheter"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faNewspaper} />
						</div>
						<div className="rowText">
							<h2>Nyheter</h2>
							<p className="intro">Genom Brfify kan nyheter förmedlas snabbt &amp; enkelt i digital form till alla boende oavsett var de är. Det är även möjligt att ladda ner och skriva ut A4-papper med nyheter till anslagstavlor.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa publika nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Sorteras automatiskt på publiceringsdatum.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Förhandsvisa publika opublicerade nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan skapa en länk och skicka.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa privata nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Kan endast läsas av inloggade användare.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa nyheter endast för viss grupp<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ex. endast för viss entré/trappuppgång vid ny portkod.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Skapa &amp; redigera nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Word-liknande textredigerare etc.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Schemalägga nyhet<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Skriv i förväg en nyhet och ange tillfälle då den automatiskt ska publiceras.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>Stöd för emojisar i nyhet</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Egen nyhetsbild<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Bild som visas längst upp i nyheten och även i lista med nyheter, samt även om publik nyhet delas i sociala medier.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Ikon som nyhetsbild<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ikon som visas när nyheter listas och längst upp på sidor om ingen nyhetsbild har valts.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Viktiga nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Markera en nyhet som viktig så visas detta med ikon och text och innebär notiser för de som valt det.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Fastnålade nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Genom att nåla fast en nyhet så visas den först bland nyheter, även om det finns nyare nyheter.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Automatisk publicering till Facebook-grupp<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>När en ny nyhet publiceras skriver Brfify automatiskt ett nytt inlägg i föreningens Facebook-grupp med rubrik, ingress och länk till nyhet.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Nyhetsutdrag (A4) för anslagstavlor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>I admin kan en PDF (A4) laddas ner som automatiskt innehåller de senaste nyheterna. Kan sedan exempelvis skrivas ut och sättas upp på infotavlor.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Reagera på nyhet<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlemmar kan i appen reagera på nyheter likt reaktioner på inlägg i sociala medier.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>


				<div>
					<a id="sidor"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faFile} />
						</div>
						<div className="rowText">
							<h2>Sidor</h2>
							<p className="intro">I Brfify finns även "vanliga" informationssidor, den <span className="highlightedText">digitala bo-pärmen</span> skulle man kunna säga. Här kan föreningar förslagsvis förmedla mer statisk information som inte förändras så ofta.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa publika sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Sorteras automatiskt i bokstavsordning A-Ö</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa olistade sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>(vid kännedom om adress)</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Förhandsvisa publika opublicerade sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan skapa en länk så att de med länken kan förhandsvisa sidan innan den publiceras</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa privata sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Kan endast läsas av inloggade användare.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa sidor endast för viss grupp<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ex. en viss entré/trappuppgång</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Skapa &amp; redigera sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Word-liknande textredigerare etc.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Stöd för emojisar på sidor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Även bilder, länkar, rubriker, stycken, tabeller etc.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Sidbild<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Bild som visas längst upp på sidan och även om publik sida delas i sociala medier.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Sidikon<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ikon som visas när sidor listas och längst upp på sidor om ingen sidbild har valts.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="viktigadatum"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCircleExclamation} mask={faCalendar} transform="shrink-10 down-2.7" />
						</div>
						<div className="rowText">
							<h2>Viktiga datum</h2>
							<p className="intro">Med Brfify uppmärksammas enkelt viktiga datum i föreningen, exempelvis betaldatum för avier, gårdsmingel, städdag eller stämma.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa publika viktiga datum<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Sorteras automatiskt utifrån händelsedatum.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa privata viktiga datum<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Kan endast läsas av inloggade användare.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Redigera viktiga datum<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Titel, beskrivning, datum, tid, publicerad, publik/privat.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="samhallsinformation"></a>
					<div>
						<div className="rowIcon">
							<img src={smhiIconRed} alt="" style={{width: '102px'}} />
							<img src={smhiIconOrange} alt="" style={{width: '102px'}} />
							<img src={smhiIconYellow} alt="" style={{width: '102px'}} />
						</div>
						<div className="rowText">
							<h2>Information från samhället</h2>
							<p className="intro">Med Brfify förmedlas snabbt och automatiskt <span className="highlightedText">viktiga meddelanden</span> från samhället. På så vis slipper ni som fastighetsägare att göra det och boende får informationen snabbt.</p>
							<p>Även kunskap och information kring exempelvis beredskap, vård och XXX från myndigheter hämtas automatiskt och hålls uppdaterat.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Viktigt Meddelande till Allmänheten, <a href="https://www.msb.se/vma" target="_blank">VMA</a><br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visa automatiskt VMA från Sveriges radio, SR, rörande föreningens område.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Varningar från SMHI<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visa automatiskt varningar (gul, orange &amp; röd) från SMHI rörande föreningens område.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Från Krisinformation.se<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visa automatiskt varningar från Krisinformation.se rörande föreningens område.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Notis vid viktiga meddelanden<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Se avsnitt <a href="#notiser">Notiser</a></span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Hantera Viktiga meddelanden<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin ställer in om samhällsinformation ska visas för boende.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>


				<div>
					<a id="bokning"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCalendarDays} />
						</div>
						<div className="rowText">
							<h2>Bokning</h2>
							<p className="intro">Med Brfify-appen bokar boende enkelt föreningens objekt, exempelvis tvättstugan eller pingisbordet, istället för att behöva springa iväg till någon tavla eller papperslapp. Dessutom alltid enligt <span className="highlightedText">föreningens regler</span>, enkelt att göra rätt!</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa bokningsobjekt &amp; bokningsstatus<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ser tillgänlighet, ej vem som bokat.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Boka ett bokningsobjekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Från nu och 90 dagar framåt (samt ev. andra regler, ex. kortare tid).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa &amp; radera egna bokningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Gäller de som ännu inte inträffat.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Visa &amp; radera medlemmars bokningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Nuvarande &amp; raderade bokningar (90 dagar).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Skapa &amp; redigera bokningsobjekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Namn, ikon, beskrivning, regler &amp; aktiv/inaktiv.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										{ /*
										<tr>
											<td>
												Avancerade regler för bokning<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Välj fritt mellan olika möjliga begränsningar/regler:<br/>
													&bull; Minimitid på bokning<br/>
													&bull; Maximitid på bokning<br/>
													&bull; Minimitid till tidigare bokning<br/>
													&bull; Minimitid till medlems tidigare bokning<br/>
													&bull; Maximidagar framåt att boka<br/>
													&bull; Tidsramar för alla veckodagar som är tillåtet att boka<br/>
													&bull; Text med regler som behöver godkännas för att kunna slutföra bokning<br/>
													&bull; Första bokningstillfälle
												</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										*/}
										<tr>
											<td>
												Dela bokningsobjekt mellan flera föreningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Flera föreningar som vardera har Brfify kan ha gemensamma bokningsobjekt.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Tillträda för att boka<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan ställa in att medlem måste tillträda/flytta in för att få boka.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Faktureringsunderlag<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Automatisk export av underlag till kassör/ekonomisk förvaltare för debitering till medlemmar, ex. på avier.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Automatisk digital nyckel till bokningsobjekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Boende som bokat objekt där det finns digitala nycklar tilldelats automatiskt en sådan på vald tid innan och efter bokningen.<br/>Stödjer: Parakey (kontakta för fler)</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="notiser"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faBell} />
						</div>
						<div className="rowText">
							<h2>Notiser</h2>
							<p className="intro">Med Brfify-appen kan respektive medlem själv välja om hen vill ha notiser och vid vilka händelser.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Alla nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid alla nya nyheter från föreningen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Viktiga nyheter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid alla nya, av styrelsen markerade <u>viktiga</u>, nyheter från föreningen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Innan bokningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis innan egna bokningar.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Nya inloggningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid nya inloggningar för den aktuella användaren.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Nya omröstningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid alla nya omröstningar från föreningen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Problem med hissen (Kone)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid förändrad status för den aktuella användarens hiss.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Förändrad kö-plats<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid förändrad kö-plats för den aktuella användaren.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Viktiga meddelanden<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notis vid viktiga meddelanden från myndigheter.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="kointresse"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faListOl} />
						</div>
						<div className="rowText">
							<h2>Kö &amp; intresse</h2>
							<p className="intro">Med Brfify-appen kan medlemmar <span className="highlightedText">själva dygnet runt</span> ställa sig i kö, följa sin plats i kön, uppdatera kontaktuppgifter eller gå ur kön. Ett rättvist &amp; enkelt system som underlättar för alla. Kö-objekt kan även användas för att inventera/visa intresse för en eventuell satsning.</p>
							<p>Senare kommer även ett helt <span className="highlightedText">digitalt automatiserat flöde</span> börja testas där medlem efter erbjudande så fort det är hens tur och vid ja signerar avtal automatiskt med BankID och digitala nycklar tilldelas.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa kö-objekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Sorteras automatiskt i bokstavsordning.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Ställa sig själv i kö<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Anger kontaktuppgifter.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Ställa medlem i kö<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan manuellt ställa medlem i kö, exempelvis om medlem inte har möjlighet att använda appen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Gå själv ur kö<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlem kan själv gå ur en kö, avregistera sig från kön.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Uppdatera egna kontaktuppgifter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlem kan själv uppdatera sina kontaktuppgifter.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Skapa &amp; redigera kö-objekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Namn, beskrivning, ikon &amp; publicerad/avpublicerad</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Tillträda för att köa<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan ställa in att medlem måste tillträda/flytta in för att få ställa sig i kö eller visa intresse.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Anmälan<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ex. för stämma eller gårdsmingel.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Hantera kö<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Lägga till anteckningar och ta bort från kö</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Statistik (ex. kötid)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Plats i kön, väntetid, genomsnittlig väntetid.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Automatisk köhantering<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Erbjudande, avtalssignering &amp; digitala nycklar</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="atkomst"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faKey} />
						</div>
						<div className="rowText">
							<h2>Åtkomst – Portkoder &amp; digitala nycklar</h2>
							<p>Med Brfify-appen kommer inloggade medlemmar enkelt åt <span className="highlightedText">aktuella portkoder</span> och kan själva hantera sina digitala nycklar.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Se portkod (även offline)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlemmar kan se en eller flera portkoder samt titel och instruktion för dessa. Ex. bara för egna entrén.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera portkoder<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Rubrik, beskrivning, portkod/hisskod och vilka grupper den ska visas för.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera digitala nycklar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Om fastigheten har digitala nycklar kan medlemmar tillåtas att hantera sina egna nycklar i appen.<br/>Stödjer: Parakey (kontakta för fler)</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Inställningar för digitala nycklar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Antal nycklar per objekt</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Registrerade taggar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlemmar kan se taggar/blipp/kort som är registerade i passagesystemet för den aktuella lägenheten/objektet.<br/>Stödjer: Manuell import</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="arenden"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faMessage} />
						</div>
						<div className="rowText">
							<h2>Ärenden – Felanmälan, frågor &amp; beställningar</h2>
							<p>Med den smarta, enkla &amp; guidande ärendehanteringen i Brfify underlättas medlemmars, styrelsers och förvaltares vardag genom kortare ledtider, effektivare arbete &amp; bättre kommunikation.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Ärendehantering<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>E-postmeddelanden faller lätt ”mellan stolarna” eller kommer inte fram, då är det bättre med ett enkelt ärendehanteringssystem som hanteras mellan båda parter direkt i Brfify.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkGreen" /></td>
										</tr>
										{/*
										<tr>
											<td>
												&nbsp; - Integration: E-post<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic', marginLeft: '18px'}}>Skicka iväg nytt ärende som ett e-postmeddelande till angiven e-postadress och med innehåll enligt inställning.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										*/}
										<tr>
											<td>
												Guidad anmälan<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Anmälan av fel eller ställa frågor genom steg/guidning som gör det ännu enklare att felanmäla och hitta svar innan anmälan skickas in. Även att felanmälan/frågan kan ske till olika ställen beroende på vad ärendet gäller, ex. om föreningen har olika avtal med olika leverantörer eller om frågan ska till styrelsen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										{/*
										<tr>
											<td>
												&nbsp; - Integration: XXX<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic', marginLeft: '18px'}}>XXX</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Inställningar för ärenden
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										*/}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="rostning"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCheckToSlot} />
						</div>
						<div className="rowText">
							<h2>Röstning &amp; tycka till</h2>
							<p>Med Brfify tar styrelsen enkelt reda på vad medlemmar tycker genom olika frågor/omröstningar.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Rösta i aktiva omröstningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Endast en gång per lägenhet oavsett antal inloggningar/medlemmar.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Skapa &amp; redigera omröstningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Fråga, beskrivning &amp; start- och slut-datum</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa resultat &amp; grafer<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Spara grafer som bild</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="erbjudande"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPercent} />
						</div>
						<div className="rowText">
							<h2>Erbjudande</h2>
							<p>Med Brfify kan medlemmar <span className="highlightedText">automatiskt få erbjudande</span> och styrelsen kan även komplettera med eventuella <span className="highlightedText">lokala erbjudanden,</span> exempelvis från kvartersrestaurangen.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Automatiska erbjudanden online
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Egna erbjudanden
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera erbjudanden
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="atervinning"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faRecycle} />
						</div>
						<div className="rowText">
							<h2>Återvinning</h2>
							<p>Med Brfify blir något så tråkigt och ofta frusterande som sopor &amp; soprum enklare genom förbättrad information.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Beställ matavfallspåsar med en knapptryckning<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>I sop-/återvinningsrum ligger det ofta bruna matavfallspåsar i papp som någon måste beställa manuellt, fram till nu. Genom en enda knapptryckning i appen eller admin beställs automatiskt nya avfallspåsar enligt fördefinierade inställningar. Kräver stöd från kommun eller annan återvinningsleverantör.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Se historiska &amp; kommande tömningsdagar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Kräver stöd från kommun eller annan återvinningsleverantör.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Se avfallsstatistik<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Kräver stöd från kommun eller annan återvinningsleverantör.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Hantera återvinningsleverantörer
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Se återvinningsstation<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Alla FTIs stationer i kommunen visas.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Sorteringsguide<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>TBD</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="hissar"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faElevator} />
						</div>
						<div className="rowText">
							<h2>Hissar</h2>
							<p>Med Brfify och uppkopplade hissar från <Link to="/samarbete/kone">Kone</Link> underlättas vardagen genom bättre information och praktiska funktioner.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Se hiss-status (Kone)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>I realtid visas hissarnas status och de boende som önskar kan även få notis vid förändring så att de inte manuellt behöver titta status i appen. Vid incident visas även status på ärende, exempelvis att tekniker är på väg, på plats eller lösningskommentar. Funktionen finns för kompatibla hissar och kräver en extratjänst (API) från Kone.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Notis vid förändrad status<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Se avsnitt <a href="#notiser">Notiser</a></span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Åk hiss med appen (Kone)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Direkt via appen kan boende beställa en resa från ett våningsplan till ett annat. Boende slipper även ange hisskod om detta är inställt i hissen. Funktionen finns för kompatibla hissar och kräver en extratjänst (API) från Kone.<br/>
												&bull; Lägenhet &harr; Entréplan<br/>
												&bull; Lägenhet &harr; Källare<br/>
												&bull; Entréplan &harr; Källare</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="forbrukningsstatistik"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faGaugeHigh} />
						</div>
						<div className="rowText">
							<h2>Förbrukningsstatistik</h2>
							<p>Med Brfify-appen kan medlemmar följa sin &amp; föreningens förbrukning av el &amp; vatten.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Gemensam el &amp; värme<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visa fastighetens förbrukning av el och värme.<br/>Stödjer: Eon Elnät &amp; Eon fjärrvärme (tilläggstjänst från Eon)</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												IMD – individuell mätning av vatten &amp; el<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visa respektive lägenhets egen förbrukning av vatten och/eller el med mera. Import görs manuellt via fil eller automatiskt via API.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>


				{ /*
				<div>
					<a id="facebookgrupp"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faFacebook} />
						</div>
						<div className="rowText">
							<h2>Facebook-grupp</h2>
							<p>Om föreningen har en grupp på Facebook kan Brfify interagera med den för att <span className="highlightedText">underlätta för styrelsen.</span></p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Automatisk publicering av nyhet<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>När en ny nyhet publiceras skriver Brfify automatiskt ett nytt inlägg i föreningens Facebook-grupp med rubrik, ingress och länk till nyhet.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										{ /*
										<tr>
											<td>
												Automatisk behörighetshantering<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Brfify sköter automatiskt godkännande av nya användare i Facebook-gruppen och även borttagningen av dem när de inte längre är medlemmar.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										* /}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				*/}



				<div>
					<a id="fastighetsdagbok"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faBook} />
						</div>
						<div className="rowText">
							<h2>Fastighetsdagbok</h2>
							<p>För att bidra till <span className="highlightedText">stabilitet och kontinuitet</span> mellan och inom styrelser och förvaltare med flera har Brfify flera funktioner för att underlätta informationsöverföringen.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Incidenter<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Översvämningar, inbrott, skadegörelse med mera inträffar löpande i en fastighet och det kan därför vara bra att dokumentera dessa på ett ställe där nuvarande och kommande styrelse samt förvaltare kan komma åt dessa.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Anmaningar<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Ibland behöver styrelsen skicka en formell anmaning till medlem och det kan då vara bra att dokumentera dessa på en samlad plats.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Förfrågningar &amp; beslut<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Notera förfrågningar som inkommit från medlemmar och styrelsens beslut på dessa. Exempelvis för ombyggnation i lägenheten.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="avtalleverantorer"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faFileContract} />
						</div>
						<div className="rowText">
							<h2>Avtal &amp; leverantörer</h2>
							<p>Att löpande se över och arbeta med de avtal föreningen har är en viktig del för en <span className="highlightedText">god ekonomi</span> i föreningen. Med Brfify kan styrelsen enkelt i ett samlat verktyg arbeta med detta samt även kopiera lista med leverantörer till årsredovisningen.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Se lista med leverantörer<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Leverantörsnamn &amp; syfte/funktion.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Se utökad lista med leverantörer<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Leverantörsnamn, syfte/funktion, avtalstid, uppsägningstid, kostnad, indexklausul, fakturainfo (ex. hur ofta faktura brukar komma &amp; på vilka belopp), kontaktperson och anteckningar/övriga noteringar.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Hantera leverantörer &amp; avtal<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Lägg till, redigera och ta bort leverantörer/avtal.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Kostnadsprognos<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Utifrån avtalstid, pris och indexklausul beräknas föreningens kommande kostnader vilket kan vara ett praktiskt underlag vid budgetarbete.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="signering"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faSignature} />
						</div>
						<div className="rowText">
							<h2>Signering</h2>
							<p>Med Brfify signeras styrelseprotokoll, hyresavtal och andra dokument snabbt, enkelt och säkert med avancerade digitala signaturer (AES) genom Mobilt BankID och enligt EU-förordningen eIDAS. Ofta till samma kostnad som ett frimärke.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Ladda upp valfri PDF och signera
											</td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Hyresavtal
											</td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="objekt"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faShapes} />
						</div>
						<div className="rowText">
							<h2>Objekt</h2>
							<p>För effektiv och automatisk hantering kopplas medlemmar alltid till ett eller flera objekt vilket kan vara bostadsrätter eller hyresobjekt såsom parkeringsplatser eller extraförråd mm. Flera medlemmar kan kopplas till samma objekt, exempelvis vid delat ägande. Genom objekt kan information om objektet tillhandahållas, exempelvis storlek på lägenhet, elmätarnummer eller beskrivning var aktuella lägenhetens förråd finns, <span className="highlightedText">utan att behöva anges för varje medlem</span>. Framöver kommer det även vara möjligt att inhämta förbrukningsstatistik, exempelvis vatten eller el.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa mina objekt &amp; information om dessa, ex.:<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>&bull; Storlek (m2/m3)<br/>
												&bull; Adress<br/>
												&bull; Placering (ex. "förråd finns i källare")<br/>
												&bull; Våningsplan<br/>
												&bull; Avgift/hyra<br/>
												&bull; Elmätarnummer/Anläggnings-ID</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Redigera objekt &amp; dess info
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Koppla medlemmar med objekt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Koppla en eller flera medlemmar med ett objekt så att de ser information om omjektet i appen samt att andra automatiska funktioner kan utföras.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="inuttrade"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPeopleCarryBox} />
						</div>
						<div className="rowText">
							<h2>In- &amp; utträde</h2>
							<p>I en förening är det naturligt att människor flyttar in &amp; ut, att nya medlemmar tillkommer och att befintliga medlemmar går ur föreningen. Med hjälp av Brfify kan in- &amp; utflytten ske smidigt för alla parter, även grannarna.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Tillgång till app
											</td>
											<td colSpan={3}><i>Se avsnitt "Åtkomst till Brfify"</i></td>
										</tr>
										<tr>
											<td>
												Checklista inflytt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Från startsidan i appen hittar medlemmar som ska flytta in enkelt en checklista för vad hen behöver göra, exempelvis teckna hemförsäkring, anmäla inflytt till elnät och så vidare.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Checklista utflytt<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Från startsidan i appen hittar medlemmar som ska flytta ut enkelt en checklista för vad hen behöver göra, exempelvis säga upp elavtal mm.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Hantera checklistor<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan skapa, redigera och ta bort en eller flera checklistor.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Förvarning till andra boende<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>På startsidan i appen visas ett meddelande till alla boende i samma trapphus som den inflyttande (namn visas inte).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="avier"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faFileInvoiceDollar} />
						</div>
						<div className="rowText">
							<h2>Avier – Årsavgifter &amp; hyror</h2>
							<p>Med Brfify ser medlemmar framöver enkelt sina avier och kan i framtiden även snabbt &amp; enkelt betala dem med Swish.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Se mina avier<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Medlem ser enkelt sina aktuella och historiska avier för årsavgift och eventuella hyresobjekt. Vid stöd från aviseringssystem kan även avin visas som PDF.<br/>Stödjer: TBD</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Se samtliga avier<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan se alla medlemmars avier.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Betala mina avier<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Utöver att kunna se sina avier är det även praktiskt för medlemmar som önskar att direkt kunna betala avin, exempelvis via Swish.<br/>Stödjer: TBD &amp; Swish</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="inarheten"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faMapLocationDot} />
						</div>
						<div className="rowText">
							<h2>I närheten</h2>
							<p>Med Brfify hittar boende enkelt service i närheten av fastigheten, exempelvis postboxar, serviceombud, återvinningsstationer och skyddsrum med mera. Även information
								
								
								 enkelt automatiskt hämtad information kring utdelningsdagar och ombud rörande föreningens postnummer.</p>
							<p>I en mer orolig omvärld ökar behovet av information kring trygghet och säkerhet, exempelvis var närmsta skyddsrummet finns och vad som krävs av mig som invånare vid en kris.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Utdelningsdatum från PostNord
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Utdelningsdatum från CityMail (tidigare Bring)
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Närmaste utlämningsställen från PostNord
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Närmaste postlådor från PostNord
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Lista skyddsrum i närheten<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Information hämtas automatiskt från MSB (Myndigheten för samhällsskydd & beredskap).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="bibliotek"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPhotoFilm} />
						</div>
						<div className="rowText">
							<h2>Bibliotek (filer &amp; media)</h2>
							<p>Som stöd till övriga funktioner finns fil- &amp; mediabibliotek där bilder, filmer och filer kan lagras. Som standard <span className="highlightedText">ingår 1 GB</span> lagringsutrymme, se <Link to="/pris">pris</Link> för mer information.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Visa publika filer/media<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Filer som är publika för alla, exempelvis dokument &amp; bilder till webbsidan &amp; appen.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa privata filer<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Filer bara administratörer ska komma åt, exempelvis signerade dokuement/avtal, styrelseprotokoll osv.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
										<tr>
											<td>
												Ladda upp &amp; hantera filer/media<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Admin kan ladda upp nya filer, bilder och videos samt hantera dessa (byta namn, flytta &amp; radera).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="design"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faPaintRoller} />
						</div>
						<div className="rowText">
							<h2>Design/Färger</h2>
							<p>Även om flera föreningar använder Brfify kan respektive brf anpassa sin del med sina färger, innehåll och inställningar.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Huvudfärg<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Bakgrundfärg i menyer och sidfot samt färg på rubriker.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Komplementfärg &amp; kontrastfärg<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Färg på ikoner och detaljer i design.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Ljust &amp; mörkt läge<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Användare kan i sina mobiler &amp; datorer numera ställa in så kallat ljust (light) eller mörkt (dark) läge (mode) vilket rör om bakgrunden ska vara ljus (ex. vit) eller mörk (ex. svart).</span>
											</td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Startsida med video &amp; slogan
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Startsida med bild &amp; slogan
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
										</tr>
										<tr>
											<td>
												Hantera design-inställningar
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa text om föreningen<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visas på webbsida längst ner på alla sidor.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Redigera text om föreningen
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa länk till ev. Facebook-grupp<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visas på webbsida längst ner på alla sidor och i appen på startsidan.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Redigera ev. länk till Facebook-grupp
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Visa tre ”info-kolumner”<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Visas på webbsida längst ner på alla sidor och i appen på startsidan.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera tre ”info-kolumner”
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="statistik"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faChartPie} />
						</div>
						<div className="rowText">
							<h2>Statistik</h2>
							<p>Styrelsen följer enkelt hur mycket Brfify används och på vilket sätt.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Antal inloggade/aktiverat appen
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Antal inloggningar per lgh
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Användande över tid
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Antal medlemmar per notis-typ
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Medeltid för köer
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="admin"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faGear} />
						</div>
						<div className="rowText">
							<h2>Administration (utöver ovan)</h2>
							<p>Alla funktioner i Brfify hanteras genom det kraftfulla och lättnavigerade admin-verktyget. Utöver funktioner som redan listas följer här några ytterligare funktioner.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Skapa &amp; hantera administratörer
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Byta eget lösenord
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera boende &amp; grupper<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>&bull; Visa boende<br/>
												&bull; Redigera grupptillhörighet<br/>
												&bull; Visa antal aktiva inbjudningar<br/>
												&bull; Visa antal aktiva inloggningar<br/>
												&bull; Skapa ny inbjudan<br/>
												&bull; Inaktivera alla aktiva inloggningar<br/>
												&bull; Inaktivera alla aktiva inbjudningar</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Hantera grupper<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>&bull; Skapa grupper<br/>
												&bull; Redigera grupper<br/>
												&bull; Redigera medlemmar/objekt i grupper<br/>
												&bull; Visa antal medlemmar</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Begränsa åtkomst till vissa funktioner<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Exempelvis att förvaltare med admin-konto endast kommer åt kö-hantering &amp; nyheter.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Stämma<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>&bull; Exportera medlemsregister till en PDF som kan användas som närvarolista/röstlängd.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
											<td><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>



				<div>
					<a id="api"></a>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faCode} />
						</div>
						<div className="rowText">
							<h2>API &amp; integrationer</h2>
							<p>Även om Brfify har massor av funktioner blir plattformen ännu bättre tillsammans med andra! Välkommen att använda Brfifys API eller någon av de färdiga integrationer som erbjuds via samarbetspartners.</p>

							<div className={functionsTable}>
								<table>
									<thead>
										<tr>
											<th>Funktion:</th>
											<th>Webbsida:</th>
											<th>App:</th>
											<th>Admin:</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Lättanvänt API (HTTP REST)<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Teknisk dokumentation kommer.</span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
										<tr>
											<td>
												Färdiga integrationer<br/>
												<span style={{fontSize: '80%', color: 'gray', fontStyle: 'italic'}}>Läs mer under sidan <Link to="/samarbete">Samarbete</Link></span>
											</td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
											<td><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div>
					<a id="ikonforklaring"></a>
					<div style={{gridTemplateColumns: 'auto'}}>
						<div>
							<h2>Ikonförklaring</h2>
							<table style={{width: '100%'}}>
								<tr>
									<td style={{width: '16px', textAlign: 'center'}}><FontAwesomeIcon icon={faCircleCheck} className="checkGreen" /></td>
									<td style={{width: 'auto', textAlign: 'left'}}>Betyder att funktionen redan finns och är redo att börja användas direkt.</td>
								</tr>
								<tr>
									<td style={{width: '16px', textAlign: 'center', paddingTop: '0px'}}><FontAwesomeIcon icon={faCirclePlus} className="checkGreen" /></td>
									<td style={{width: 'auto', textAlign: 'left', paddingTop: '0px'}}>Som ovan men att funktionen har transaktionskostnader för att användas.</td>
								</tr>
								<tr>
									<td style={{width: '16px', textAlign: 'center'}}><FontAwesomeIcon icon={faCircle} className="checkBlue" /></td>
									<td style={{width: 'auto', textAlign: 'left'}}>Betyder att funktionen är planerad och bör lanseras kring det angivna tillfället. Funktion och lanseringstillfälle kan komma att ändras.</td>
								</tr>
								<tr>
									<td style={{width: '16px', textAlign: 'center', paddingTop: '0px'}}><FontAwesomeIcon icon={faCirclePlus} className="checkBlue" /></td>
									<td style={{width: 'auto', textAlign: 'left', paddingTop: '0px'}}>Som ovan men att funktionen kommer att ha transaktionskostnader för att användas.</td>
								</tr>
								<tr>
									<td style={{width: '16px', textAlign: 'center'}}><FontAwesomeIcon icon={faCircleMinus} className="checkGray" /></td>
									<td style={{width: 'auto', textAlign: 'left'}}>Betyder att funktionen inte är relevant/aktuell för den aktuella plattformen (webbsida/app/admin).</td>
								</tr>
							</table>
						</div>
					</div>
				</div>


			</div>

		</Layout>
	)
}

export default FunktionerPage